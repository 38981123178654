<template>
	<!-- nav -->
	<nav class="nav nav--fit">
		<div class="nav__wrap">
			<a class="nav__btn nav__btn--prev">
				<i class="icon icon-left">이전 페이지</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					숙소 예약내역
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
	<!-- //nav-->
	<div id="container" class="container">
		<div id="sub" class="sub mypage">
			<!-- [D] 활성화된 tab에 active 클래스 추가 -->
			<section class="listcartegory">
				<div class="box">
					<div class="tab">
						<a href="#">사용 전</a>
						<a href="#" class="active">사용 후</a>
						<a href="#">구매 취소</a>
					</div>
				</div>
			</section>
			<!-- content -->
			<section class="content">
				<div class="row">
					<div class="textbox textbox--margin">
						<div class="textbox__icon">
							<img src="@/assets/images/img_warning.png" alt="찾으시는 결과가 없나요?" style="width:64px">
						</div>
						<h3 class="textbox__title">이용하신 레저·티켓 내역이 없어요</h3>
						<p class="textbox__description">
							 다양한 레저·티켓 리스트를 만나고 <br>
							지금 레저·티켓을 예약해보세요!
						</p>
						<div class="textbox__button">
							<button type="button" class="btn btn-primary purple">
								레저·티켓 보러가기
							</button>
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
</template>